import * as actionTypes from "../actions/actionTypes";

const initialState = {
  getLearningPostData:[],
  addLearningPost:{},
  editLearningPost:{},
  blockLearningPost:{},
  deleteLearningPost:{},
  getLearningDetails:{},
  getArticleList:{},
};


const learningPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEARNING_POST: 
    {
      return {
        ...state,
        getLearningPostData: action.payload,
      };
    }
    case actionTypes.GET_LEARNING_DETAILS: 
    {
      return {
        ...state,
        getLearningDetails: action.payload,
      };
    }
    case actionTypes.GET_ARTICLE_LIST: 
    {
      return {
        ...state,
        getArticleList: action.payload,
      };
    }
    case actionTypes.ADD_LEARNING_POST: 
    {
      return {
        ...state,
        addLearningPost: action.payload,
      };
    }
    case actionTypes.BLOCK_LEARNING_POST: 
    {
      return {
        ...state,
        blockLearningPost: action.payload,
      };
    }
    case actionTypes.EDIT_LEARNING_POST: 
    {
      return {
        ...state,
        editLearningPost: action.payload,
      };
    }
    case actionTypes.DELETE_LEARNING_POST: 
    {
      return {
        ...state,
        deleteLearningPost: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default learningPostReducer;
