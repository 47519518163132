import * as actionTypes from '../actions/actionTypes';

const initialState = {
    userList: {}
};


const userlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_LIST:
            {
                return {
                    ...state,
                    userList: action.payload
                }
            }
        default:
            {
                return state;
            }
    }
}


export default userlistReducer;