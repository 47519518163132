import { combineReducers } from "redux";
import galleryReducer from "./gallery.reducer";
import trafficLightReducer from "./trafficLights.reducer";
import categoryReducer from "./category.reducer";
import habitReducer from "./habit.reducer";
import userlistReducer from "./userlist.reducer";
import challengeReducer from "./challenge.reducer";
import learningPostReducer from "./learningPost.reducer";
import loginReducer from "./login.reducer";
import navbarReducer from "./navbar.reducer";

const rootReducer = combineReducers({
  galleryReducer,
  categoryReducer,
  habitReducer,
  userlistReducer,
  challengeReducer,
  learningPostReducer,
  loginReducer,
  navbarReducer,
  trafficLightReducer, //new static data
});

export default rootReducer;
