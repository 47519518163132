import * as actionTypes from '../actions/actionTypes';

const initialState = {
    gethabitList: {},
    blockStatus:{}
};


const habitReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_HABIT:
            {
                return {
                    ...state,
                    gethabitList: action.payload
                }
            }
        case actionTypes.BLOCK_HABIT:
            {
                return {
                    ...state,
                    blockStatus: action.payload
                }
            }
        default:
            {
                return state;
            }
    }
}


export default habitReducer;