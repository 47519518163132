export const GET_ERRORS = "GET_ERRORS";
export const GET_GALLERY_DATA = "GET_GALLERY_DATA";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_HABIT = "GET_HABIT";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_CHALLENGE = "GET_CHALLENGE";
export const GET_CONTENT_LIST = "GET_CONTENT_LIST";
export const GET_LEARNING_POST = "GET_LEARNING_POST";
export const GET_LEARNING_DETAILS = "GET_LEARNING_DETAILS";
export const GET_ARTICLE_LIST = "GET_ARTICLE_LIST";
export const GET_LOGIN_DATA = "GET_LOGIN_DATA";
// add traffic lights
export const GET_TRAFFIC_LIGHT = "GET_TRAFFIC_LIGHT";

export const ADD_USER_LIST = "ADD_USER_LIST";
export const ADD_GALLERY_DATA = "ADD_GALLERY_DATA";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_HABIT = "ADD_HABIT";
export const ADD_CHALLENGE = "ADD_CHALLENGE";
export const ADD_LEARNING_POST = "ADD_LEARNING_POST";

export const EDIT_GALLERY_DATA = "EDIT_GALLERY_DATA";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_HABIT = "EDIT_HABIT";
export const EDIT_CHALLENGE = "EDIT_CHALLENGE";
export const EDIT_LEARNING_POST = "EDIT_LEARNING_POST";

export const DELETE_GALLERY_DATA = "DELETE_GALLERY_DATA";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_HABIT = "DELETE_HABIT";
export const DELETE_USER_LIST = "DELETE_USER_LIST";
export const DELETE_CHALLENGE = "DELETE_CHALLENGE";
export const DELETE_CHALLENGE_TASK = "DELETE_CHALLENGE_TASK";
export const DELETE_LEARNING_POST = "DELETE_LEARNING_POST";

export const BLOCK_GALLERY_DATA = "BLOCK_GALLERY_DATA";
export const BLOCK_CATEGORY = "BLOCK_CATEGORY";
export const BLOCK_HABIT = "BLOCK_HABIT";
export const BLOCK_USER_LIST = "BLOCK_USER_LIST";
export const BLOCK_CHALLENGE = "BLOCK_CHALLENGE";
export const BLOCK_LEARNING_POST = "BLOCK_LEARNING_POST";

export const NAV_BUTTON_OPEN_CLOSE = "NAV_BUTTON_OPEN_CLOSE";
