import * as actionTypes from '../actions/actionTypes';

const initialState = {
    sidebarShow: 'responsive'
};


const navbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NAV_BUTTON_OPEN_CLOSE:
            {
                return {
                    ...state,
                    sidebarShow: action.payload
                }
            }
        default:
            {
                return state;
            }
    }
}


export default navbarReducer;