import * as actionTypes from '../actions/actionTypes';

const initialState = {
    categoryData: {}
};


const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORY:
            {
                return {
                    ...state,
                    categoryData: action.payload
                }
            }
        
        default:
            {
                return state;
            }
    }
}


export default categoryReducer;