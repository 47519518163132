import * as actionTypes from '../actions/actionTypes';

const initialState = {
    addGalleryData:{}
};


const galleryReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case actionTypes.ADD_GALLERY_DATA:
        {
            return{
                ...state,
                addGalleryData:action.payload
            }
        }
        default:
            {
                return state;
            }
    }
}


export default galleryReducer;