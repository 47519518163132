import * as actionTypes from '../actions/actionTypes';

const initialState = {
    challengeList: {},
    challengeEdit:{},
    challengeAdd:{},
    challengeDelete:{},
    challengeBlock:{},
    getContentList:{},
    removeChallengeTask:{}

};


const challengeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHALLENGE:
            {
                return {
                    ...state,
                    challengeList: action.payload
                }
            }
        case actionTypes.GET_CONTENT_LIST:
            {
                return {
                    ...state,
                    getContentList: action.payload
                }
            }
        case actionTypes.EDIT_CHALLENGE:
            {
                return {
                    ...state,
                    challengeEdit: action.payload
                }
            }
        case actionTypes.ADD_CHALLENGE:
            {
                return {
                    ...state,
                    challengeAdd: action.payload
                }
            }
        case actionTypes.DELETE_CHALLENGE:
            {
                return {
                    ...state,
                    challengeDelete: action.payload
                }
            }
        case actionTypes.DELETE_CHALLENGE_TASK:
            {
                return {
                    ...state,
                    removeChallengeTask: action.payload
                }
            }
        case actionTypes.BLOCK_CHALLENGE:
            {
                return {
                    ...state,
                    challengeBlock: action.payload
                }
            }
        default:
            {
                return state;
            }
    }
}


export default challengeReducer;