import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import AxiosInterceptor from "./utils/interceptor";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Loading...</div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const RedirectPage = React.lazy(() =>
  import("./views/pages/redirectPage/RedirectPage")
);

class App extends Component {
  constructor(props) {
    super(props);
    AxiosInterceptor();
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {sessionStorage.getItem("token") &&
            sessionStorage.getItem("email") ? (
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            ) : (
              <Route
                exact
                path="/login"
                name="Login Page"
                render={() => <Login />}
              />
            )}
            <Route
              name="Page 404"
              render={(props) => <RedirectPage {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
